<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_perehod + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">Переходник с 3" на 2"</div>
          <div class="im-subtitle">Адаптер для куба или колонны отдельно</div>
          <div class="im-text">
            <p>
              Очень актуальный переходник-адаптер с выхода 3" на 2" для тех,
              кто:
            </p>
            <ol>
              <li>
                Имеет свою колонну 2" и хочет купить только куб 3" DomSpirt 2;
              </li>
              <li>
                Имеет свой куб 2" и хочет купить только колонну 3" от DomSpirt
                2;
              </li>
              <li>
                Хочет сделать аппарат своими руками, но нужна вторая часть - куб
                или колонна;
              </li>
              <li>
                Хочет обновить свой старый аппарат 2" на DomSpirt 2 и делает это
                «по частям»: сначала докупив куб, потом колонну;
              </li>
              <li>
                Хочет поставить какое-то 2" устройство на 3" выход DomSpirt 2.
              </li>
            </ol>
            <p>
              В общем, при заказе куба или колонны отдельно - крайне полезная в
              хозяйстве вещь.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_perehod = require("@/pages/Main/assets/modal/m_perehod.jpg");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_perehod,
      car,
    };
  },
};
</script>

<style scoped></style>
